import React, { useState, useEffect } from "react";
import {
  useSpring,
  animated,
  config,
  Spring,
  useTrail,
  useChain,
  useSpringRef,
  useTransition,
} from "react-spring";

const values1 = {
  from: { x: 0, y: 0 },
  to: { x: -250, y: -20 },
  loop: { reverse: true },
  config: config.wobbly,
};

const values2 = {
  from: { x: -250, y: -20, transform: `rotate3d(1,1,1,45deg)` },
  to: { x: -250, y: -20, transform: `rotate3d(.5,.5,.5,90deg)` },
  loop: { reverse: true },
  config: config.wobbly,
};

export function LookL1(props) {
  return (
    <animated.g id="LGroup" className="LookL1" {...props}>
      <polygon points="598.6,315.8 598.6,312.5 598.6,165.9 557.2,165.9 557.2,353.5 598.6,353.5 	" />
    </animated.g>
  );
}



export function TestSpin({ toggle }) {
  const springRef = useSpringRef();

  const transformOrigin = `translateOrigin(20px,20px,20px)`;

  const { spring, ...rest } = useSpring({
    ref: springRef,
    config: config.wobbly,
    from: {
      transform: 
      `transformOrigin(100,100)
      translate3d(-300px,0px,0x)
      rotate3d(0.2,0.2,0.2,5deg)`,
    },
    to: {
      transform: 
      `transformOrigin(100,100)
      translate3d(-300px,10px,0px)
      rotate3d(.1,.1,.1,10deg)`,
    },
    loop: { reverse: true },
  });

  const [styles, api] = useSpring(() => ({
    from: {
      transform: `rotate3d(1,1,1,0deg)  `,
    },
  }));

  const set1 = {x1: 10, x2: 90, y:40}
  const set2 = 'x'

//   useEffect(() => {
//     api({
//       transform: `rotate3d(1,1,1,360deg) `,
//       loop: { reverse: true },
//       config: config.slow
//     });
//     if(toggle) {
//         api({
//             transform:`rotate3d(0,0,0,0deg) `
//         })
//     }
//   }, [toggle]);

  // const [styles, api] = useSpring(() => ({
  //     from: {transform: `translate3d(-300px,0px,0px) rotate3d(1,1,1,45deg)`},
  //     to: {transform: `translate3d(-320px,20px,0px) rotate3d(1,1,1,-45deg)`},
  //     loop: {reverse: true},

  // }))

  const coords1 = {
      x1: -300,
      x2: -330,
      x3:0,
      y1:15,
      y2:20,
      y3:0,
      
  }

const [animationState, setAnimationState] = useState(false)
const intervals = [0, 0.25, 0.5, 0.75, 1];
const scale = ["0,.56,0,45deg","0,.2,0,20deg", "0,.7,0,0deg", "0,.1,0,10deg", "0,0,0,0deg"];
const {animation} = useSpring({
    from: { animation: 0},
    animation: 1,
    reset: animationState,
    onRest: toggle ? () => setAnimationState(false) : () => setAnimationState(true),
    loop: {reverse: toggle ? false : true}
})

const style = {
    transform: animation
     .to({
         range: intervals,
         output: scale
     })
     .to(output => `rotate3d(${output})`)
}

  return (
    <animated.g style={style} transform="" toggle={toggle} >
    
    </animated.g>
  );
}

export const testSpringL1 = ({toggle}) => {
    return(
        <animated.g >
            <polygon  points="598.6,315.8 598.6,312.5 598.6,165.9 557.2,165.9 557.2,353.5 598.6,353.5 	" />

           
        </animated.g>
    )
}


export function LookLShort(props){
return(
    <animated.g id="look-l-short" {...props}>
          <rect x="598.6" y="315.8" width="73.2" height="37.7" />
    </animated.g>
)
}

export function LookOLeft(props){
    return (
        <animated.g {...props} id="look-o1-half1">
          <path
            d="M776.7,311.6c-17.3-10.2-28.7-29.1-28.7-51.5c0-32.8,25.6-59.5,58.7-59.5c22.2,0,41,12.1,51,29.9l29.1-29.1
           C868.6,178,839.6,163,806.7,163c-55.4,0-100.1,42.8-100.1,97.1c0,33.3,16.6,62.1,42.1,79.5L776.7,311.6z"
          />
        </animated.g>
    )
}

export function LookORight(props){
    return(
        <animated.g {...props} id="look-o1-half2">
          <path
            d="M886.8,201.4l-29.1,29.1c4.9,8.7,7.7,18.8,7.7,29.5c0,33.1-25.8,59.5-58.7,59.5c-11.1,0-21.3-2.9-30-8l-28,28
           c16.4,11.2,36.4,17.7,58,17.7c55.7,0,100.1-42.5,100.1-97.1C906.8,237.9,899.4,217.7,886.8,201.4z"
          />
        </animated.g>
    )
}

export function LookO2Cirlce(props) {
    return(
        <animated.g  id="look-o2-circle" {...props}>
             <circle cx="1033.8" cy="296.7" r="24.9" />
        </animated.g>
    )
}

export function LookO2Left(props) {
    return(
        <animated.g id="look-o2-half1" {...props}>
  <path
            d="M970.8,201.4l29.1,29.1c-4.9,8.7-7.7,18.8-7.7,29.5c0,33.1,25.8,59.5,58.7,59.5c11.1,0,21.3-2.9,30-8l28,28
           c-16.4,11.2-36.4,17.7-58,17.7c-55.7,0-100.1-42.5-100.1-97.1C950.8,237.9,958.2,217.7,970.8,201.4z"
          />
     
        </animated.g>
    )
}
export function LookO2Right(props) {
    return(
        <animated.g id="look-o2-half2" {...props}>
             <path
            d="M1080.9,311.6c17.3-10.2,28.7-29.1,28.7-51.5c0-32.8-25.6-59.5-58.7-59.5c-22.2,0-41,12.1-51,29.9l-29.1-29.1
           c18.2-23.5,47.2-38.5,80.1-38.5c55.4,0,100.1,42.8,100.1,97.1c0,33.3-16.6,62.1-42.1,79.5L1080.9,311.6z"
          />
        </animated.g>
    )
}

export function LookKLong(props) {
    return(
        <animated.g id="look-K-Long" {...props}>
              <polygon points="1247.3,249.6 1247.2,249.6 1247.2,165.9 1205.8,165.9 1205.8,353.5 1247.2,353.5 1247.2,302.4 1247.3,302.3 	" />
        </animated.g>
    )
}

export function LookKTop(props) {
    return(
        <animated.g id="look-K-top" {...props}>
             <polygon points="1247.2,248.7 1247.2,248.7 1247.2,165.9 1247.2,165.9 		" />
            <polygon points="1368.9,165.9 1319.6,165.9 1247.3,249.6 1247.3,302.3 1268.8,278.4 1297,249.1 		" />
            <polygon points="1247.2,306.3 1247.2,353.5 1247.2,353.5 1247.2,306.2 		" />
        </animated.g>
    )
}

export function LookKBottom(props) {
    return(
        <animated.g id="look-K-bottom" {...props}>
             <polygon points="1268.8,278.4 1322.3,353.5 1371,353.5 1297,249.1 	" />
        </animated.g>
    )
}




export function AtARight(props) {
    return(
<animated.g id="at-a-right" {...props}>
<polygon points="879,424.3 878.9,424.5 878.9,508.5 902.7,557.5 918,592.7 932.6,622.6 976.7,622.6 	" />
</animated.g>
    )
}

export function AtAMiddle(props) {
    return(
        <animated.g id="at-a-middle" {...props}>
      <polygon points="902.7,557.5 855.1,557.5 839.7,592.7 918,592.7 	" />
        </animated.g>
    )
}

export function AtTTop(props) {
return(
<animated.g id="at-t-top" {...props}>
<polygon points="987,435 987,472.7 1003.1,472.7 1037.9,472.7 1079.3,472.7 1129.3,472.7 1129.3,435 	" />
</animated.g>
)
}

export function AtTBottom(props) {
    return(
<animated.g id="at-t-bottom" {...props}>
<rect x="1037.9" y="472.7" width="41.4" height="149.9" />
</animated.g>
    )
}

export function AtALeft(props) {
    return(
        <animated.g {...props} id="at-a-left">
             <polygon points="878.9,424.5 781.3,622.6 825.2,622.6 839.7,592.7 855.1,557.5 878.7,508.2 878.9,508.5 	" />
        </animated.g>
    )
}

export function UsualU1Left(props) {
    return(
        <animated.g {...props} id="Layer_52">
        <path
          d="M570.7,856.8c-1.3,0.1-2.6,0.1-3.9,0.1c-23.1,0-38.5-12.6-38.5-45.5V704.1h-41.4v111.7c0,53,36.1,78.8,79.9,78.8
         c1.3,0,2.6,0,3.9-0.1V856.8z"
        />
      </animated.g>
    )
}

export function UsualU1Right(props) {
    return(
        <animated.g {...props} id="Layer_53">
        <path d="M605.1,704.1v107.4c0,30.9-13.6,43.9-34.3,45.3v37.7c41.8-1.5,75.8-27.3,75.8-78.8V704.1H605.1z" />
      </animated.g>
    )
}

export function UsualSTop(props) {
    return(
        <animated.g {...props} id="Layer_48">
        <path
          d="M749.1,808.5l40.9-26.8c-0.1,0-0.1,0-0.2-0.1c-30.4-11.8-45.2-12.4-45.2-28c0-8.9,9.4-15.3,24.8-15.3
         c16.1,0,27.2,6.7,33.9,16.1l27.7-22.1c-11.6-16.1-30.9-31.2-62.7-31.2c-34.4,0-64.6,19.4-64.6,53
         C703.8,784.2,722.3,798.8,749.1,808.5z"
        />
      </animated.g>
    )
}

export function UsualSBottom(props) {
    return(
        <animated.g {...props} id="Layer_49">
        <path
          d="M790,781.7l-40.9,26.8c0,0,0.1,0,0.1,0c26.1,9.7,47.1,13.7,47.1,30.1c0,8.9-7.3,18.6-28,18.6c-21.8,0-38.2-10-47.1-24.8
         l-30.1,20.7c11.6,22.6,36.6,41.4,77,41.4c47.6,0,69.7-24.2,69.7-57.3C837.8,810.4,823.8,794.6,790,781.7z"
        />
      </animated.g>
    )
}

export function UsualU2Left(props) {
    return(
        <animated.g {...props} id="Layer_50">
        <path
          d="M971.5,856.8c-1.3,0.1-2.6,0.1-3.9,0.1c-23.1,0-38.5-12.6-38.5-45.5V704.1h-41.4v111.7c0,53,36.1,78.8,79.9,78.8
         c1.3,0,2.6,0,3.9-0.1V856.8z"
        />
      </animated.g>
    )
}

export function UsualU2Right(props) {
    return(
        <animated.g {...props} id="Layer_51">
          <path d="M1005.8,704.1v107.4c0,30.9-13.6,43.9-34.3,45.3v37.7c41.8-1.5,75.8-27.3,75.8-78.8V704.1H1005.8z" />
        </animated.g>
    )
}

export function UsualALeft(props) {
    return(
        <animated.g {...props} id="Layer_59">
          <polygon points="1176.1,693.6 1078.6,891.6 1122.5,891.6 1137,861.8 1152.3,826.5 1176,777.3 1176.1,777.6 	" />
        </animated.g>
    )
}
export function UsualARight(props){
    return(
        <animated.g {...props} id="Layer_58">
          <polygon points="1176.3,693.3 1176.1,693.6 1176.1,777.6 1200,826.5 1215.3,861.8 1229.8,891.6 1273.9,891.6 	" />
        </animated.g>
    )
}
export function UsualAMiddle(props) {
    return(
        <animated.g {...props} id="Layer_60">
          <polygon points="1200,826.5 1152.3,826.5 1137,861.8 1215.3,861.8 	" />
        </animated.g>
    )
}

export function UsualLLong(props) {
    return(
        <animated.g {...props} id="Layer_72">
          <polygon points="1360.5,854 1360.5,850.6 1360.5,704.1 1319.1,704.1 1319.1,891.6 1360.5,891.6 	" />
        </animated.g>
    )
}
export function UsualLShort(props) {
    return(
        <animated.g {...props} id="Layer_71">
          <rect x="1360.5" y="854" width="73.2" height="37.7" />
        </animated.g>
    )
}

export function ThingsTTop(props) {
    return(
        <animated.g {...props} id="Layer_55">
          <polygon points="396.6,973.2 396.6,1010.8 412.7,1010.8 447.4,1010.8 488.9,1010.8 538.9,1010.8 538.9,973.2 	" />
        </animated.g>
    )
}

export function ThingsTBottom(props) {
    return(
        <animated.g {...props} id="Layer_54">
        <rect x="447.4" y="1010.8" width="41.4" height="149.9" />
      </animated.g>
    )
}

export function ThingsHLeft(props) {
    return(
        <animated.g {...props} id="Layer_29">
        <animated.g >
          <rect x="584" y="973.2" width="41.2" height="187.5" />
        </animated.g>
      </animated.g>
    )
}

export function ThingsHRight(props) {
    return(
        <animated.g {...props} id="Layer_28">
        <polygon points="706.7,973.2 706.7,1042.9 706.7,1042.9 706.7,1080.5 706.7,1080.5 706.7,1160.7 748.1,1160.7 748.1,973.2 	" />
      </animated.g>
    )
}

export function ThingsHMiddle(props) {
    return(
        <animated.g {...props} id="Layer_30">
        <rect x="623.2" y="1042.9" width="85.7" height="37.7" />
      </animated.g>
    )
}

export function ThingsI(props) {
    return(
        <animated.g {...props} id="Layer_79">
        <path d="M813.8,973.2h41.4v187.5h-41.4V973.2z" />
      </animated.g>
    )
}

export function ThingsNLeft(props) {
    return(
        <animated.g {...props} id="Layer_21">
        <animated.g >
          <polygon points="920.5,1160.7 961.4,1160.7 961.4,1054.4 920.5,1017.6 		" />
        </animated.g>
      </animated.g>
    )
}

export function ThingsNMiddle(props) {
    return(
        <animated.g {...props} id="Layer_80">
          <animated.g>
            <polygon points="920.5,965.9 920.5,965.9 920.5,965.9 		" />
            <polygon points="920.5,965.9 920.5,1017.6 961.4,1054.4 1087.3,1168 1087.3,1116.7 		" />
          </animated.g>
        </animated.g>
    )
}

export function ThingsNRight(props) {
    return(
        <animated.g {...props} id="Layer_22">
        <polygon points="1046.4,973.2 1046.4,1079.7 1046.4,1079.7 1046.4,1079.7 1087.3,1116.7 1087.3,973.2 	" />
      </animated.g>
    )
}

export function ThingsGTop(props) {
    return(
        <animated.g {...props} id="Layer_16">
        <path
          d="M1186.4,1086.2c-1.9-5.9-3-12.4-3-19.2c0-33.9,26.1-59.2,59.5-59.2c17.2,0,33.4,5.9,44.7,17.2l28-24.5
         c-18.6-19.1-43.9-30.4-72.7-30.4c-55.7,0-100.9,40.9-100.9,96.9c0,6.6,0.6,13,1.8,19.2H1186.4z"
        />
      </animated.g>
    )
}

export function ThingsGBottom(props) {
    return(
        <animated.g {...props} id="Layer_15">
        <path
          d="M1283.8,1086.2v25.2c-10.5,9.4-24.8,14.5-40.9,14.5c-26.7,0-48.7-16-56.5-39.8h-42.6c9,45.8,49.9,77.4,99.1,77.4
         c33.4,0,62.4-15.3,79.6-38.2v-39.2H1283.8z"
        />
      </animated.g>
    )
}

export function ThingsGMiddle(props) {
    return(
        <animated.g {...props} id="Layer_17">
        <polygon points="1234.6,1086.2 1283.8,1086.2 1283.8,1086.2 1322.5,1086.2 1322.5,1049.6 1234.6,1049.6 	" />
      </animated.g>
    )
}

export function ThingsSTop(props) {
    return(
        <animated.g {...props} id="Layer_33">
        <path
          d="M1422.2,1077.4l40.9-26.8c-0.1,0-0.1,0-0.2-0.1c-30.4-11.8-45.2-12.4-45.2-28c0-8.9,9.4-15.3,24.8-15.3
         c16.1,0,27.2,6.7,33.9,16.1l27.7-22.1c-11.6-16.1-30.9-31.2-62.7-31.2c-34.4,0-64.6,19.4-64.6,53
         C1376.9,1053.2,1395.4,1067.8,1422.2,1077.4z"
        />
      </animated.g>
    )
}

export function ThingsSBottom(props) {
    return(
        <animated.g {...props} id="Layer_34">
        <path
          d="M1463.1,1050.6l-40.9,26.8c0,0,0.1,0,0.1,0c26.1,9.7,47.1,13.7,47.1,30.1c0,8.9-7.3,18.6-28,18.6
         c-21.8,0-38.2-10-47.1-24.8l-30.1,20.7c11.6,22.6,36.6,41.4,77,41.4c47.6,0,69.7-24.2,69.7-57.3
         C1510.9,1079.4,1496.9,1063.5,1463.1,1050.6z"
        />
      </animated.g>
    )
}

export function WithWLeft(props){
    return(
        <animated.g {...props} id="with-w-left">
          <polygon points="655.6,1341.3 655.6,1341.3 620.9,1242.2 578.9,1242.2 647.3,1437.3 672.2,1388.7 	" />
        </animated.g>
    )
}

export function WithWMiddle(props){
    return(
        <animated.g {...props} id="with-w-mid">
        <polygon points="756.8,1341.3 706.2,1240.9 655.6,1341.3 672.2,1388.7 706.2,1322.4 740.2,1388.7 	" />
      </animated.g>
    )
}

export function WithWRight(props){
    return(
        <animated.g {...props} id="with-w-right">
        <polygon points="791.5,1242.2 756.8,1341.3 756.8,1341.3 740.2,1388.7 765.1,1437.3 833.5,1242.2 	" />
      </animated.g>
    )
}

export function WithI(props){
    return(
        <animated.g {...props} id="Layer_78">
        <path d="M879.9,1242.2h41.4v187.5h-41.4V1242.2z" />
      </animated.g>
    )
}

export function WithTTop(props){
    return(
        <animated.g {...props} id="Layer_27">
        <polygon points="966.7,1242.2 966.7,1279.9 982.8,1279.9 1017.6,1279.9 1059,1279.9 1109.1,1279.9 1109.1,1242.2 	" />
      </animated.g>
    )
}

export function WithTBottom(props){
    return(
        <animated.g {...props} id="Layer_26">
        <rect x="1017.6" y="1279.9" width="41.4" height="149.9" />
      </animated.g>
    )
}

export function WithHLeft(props){
    return(
        <animated.g {...props} id="Layer_24">
        <animated.g >
          <rect x="1154.2" y="1242.2" width="41.2" height="187.5" />
        </animated.g>
      </animated.g>
    )
    }

    export function WithHMiddle(props){
        return(
            <animated.g {...props} id="Layer_25">
            <rect x="1193.3" y="1311.9" width="85.7" height="37.7" />
          </animated.g>
        )
    }

    export function WithHRight(props){
        return(
            <animated.g {...props} id="Layer_23">
            <polygon
              points="1276.9,1242.2 1276.9,1311.9 1276.8,1311.9 1276.8,1349.6 1276.9,1349.6 1276.9,1429.8 1318.3,1429.8 
             1318.3,1242.2 	"
            />
          </animated.g>
        )
    }

    export function UnusU1Left(props) {
        return(
            <animated.g {...props} id="Layer_39">
            <path
              d="M343.9,1664c-1.3,0.1-2.6,0.1-3.9,0.1c-23.1,0-38.5-12.6-38.5-45.5v-107.4h-41.4V1623c0,53,36.1,78.8,79.9,78.8
             c1.3,0,2.6,0,3.9-0.1V1664z"
            />
          </animated.g>
        )
    }

    export function UnusU1Right(props) {
        return(
            <animated.g {...props} id="Layer_40">
            <path d="M378.3,1511.3v107.4c0,30.9-13.6,43.9-34.3,45.3v37.7c41.8-1.5,75.8-27.3,75.8-78.8v-111.7H378.3z" />
          </animated.g>
        )
    }

    export function UnusNLeft(props) {
        return(
            <animated.g {...props} id="Layer_18">
            <animated.g >
              <polygon points="483.3,1698.9 524.2,1698.9 524.2,1592.6 483.3,1555.7 		" />
            </animated.g>
          </animated.g>
        )
    }

    export function UnusNMid(props) {
        return(
            <animated.g {...props} id="Layer_20">
            <animated.g >
              <polygon points="483.3,1504 483.3,1504 483.3,1504 		" />
              <polygon points="483.3,1504 483.3,1555.7 524.2,1592.6 650.1,1706.1 650.1,1654.8 		" />
            </animated.g>
          </animated.g>
        )
    }

    export function UnusNRight(props) {
        return(
            <animated.g {...props} id="Layer_19">
            <polygon points="609.2,1511.3 609.2,1617.9 609.2,1617.8 609.2,1617.8 650.1,1654.8 650.1,1511.3 	" />
          </animated.g>
        )
    }

    export function UnusU2Left(props) {
        return(
            <animated.g {...props} id="Layer_41">
            <path
              d="M797.6,1664c-1.3,0.1-2.6,0.1-3.9,0.1c-23.1,0-38.5-12.6-38.5-45.5v-107.4h-41.4V1623c0,53,36.1,78.8,79.9,78.8
             c1.3,0,2.6,0,3.9-0.1V1664z"
            />
          </animated.g>
        )
    }

    export function UnusU2Right(props) {
        return(
            <animated.g {...props} id="Layer_42">
            <path d="M831.9,1511.3v107.4c0,30.9-13.6,43.9-34.3,45.3v37.7c41.8-1.5,75.8-27.3,75.8-78.8v-111.7H831.9z" />
          </animated.g>
        )
    }

    export function UnusSTop(props) {
        return(
            <animated.g {...props} id="Layer_35">
            <path
              d="M975.9,1615.7l40.9-26.8c-0.1,0-0.1,0-0.2-0.1c-30.4-11.8-45.2-12.4-45.2-28c0-8.9,9.4-15.3,24.8-15.3
             c16.1,0,27.2,6.7,33.9,16.1l27.7-22.1c-11.6-16.1-30.9-31.2-62.7-31.2c-34.4,0-64.6,19.4-64.6,53
             C930.6,1591.5,949.1,1606,975.9,1615.7z"
            />
          </animated.g>
        )
    }

    export function UnusSBottom(props) {
        return(
            <animated.g {...props} id="Layer_36">
            <path
              d="M1016.8,1588.9l-40.9,26.8c0,0,0.1,0,0.1,0c26.1,9.7,47.1,13.7,47.1,30.1c0,8.9-7.3,18.6-28,18.6
             c-21.8,0-38.2-10-47.1-24.8l-30.1,20.7c11.6,22.6,36.6,41.4,77,41.4c47.6,0,69.7-24.2,69.7-57.3
             C1064.6,1617.6,1050.6,1601.8,1016.8,1588.9z"
            />
          </animated.g>
        )
    }

    export function UnusU3Left(props) {
        return(
            <animated.g {...props} id="Layer_37">
            <path
              d="M1198.3,1664c-1.3,0.1-2.6,0.1-3.9,0.1c-23.1,0-38.5-12.6-38.5-45.5v-107.4h-41.4V1623c0,53,36.1,78.8,79.9,78.8
             c1.3,0,2.6,0,3.9-0.1V1664z"
            />
          </animated.g>
        )
    }

    export function UnusU3Right(props) {
        return(
            <animated.g {...props} id="Layer_38">
            <path d="M1232.6,1511.3v107.4c0,30.9-13.6,43.9-34.3,45.3v37.7c41.8-1.5,75.8-27.3,75.8-78.8v-111.7H1232.6z" />
          </animated.g>
        )
    }

    export function UnusALeft(props) {
        return(
            <animated.g {...props} id="Layer_44">
            <polygon points="1402.9,1500.8 1305.4,1698.9 1349.3,1698.9 1363.8,1669 1379.1,1633.7 1402.8,1584.5 1402.9,1584.8 	" />
          </animated.g>
        )
    }

    export function UnusARight(props) {
        return(
            <animated.g {...props} id="Layer_43">
            <polygon points="1403.1,1500.6 1402.9,1500.8 1402.9,1584.8 1426.8,1633.7 1442.1,1669 1456.6,1698.9 1500.8,1698.9 	" />
          </animated.g>
        )
    }

    export function UnusAMid(props) {
        return(
            <animated.g {...props} id="Layer_45">
            <polygon points="1426.8,1633.7 1379.1,1633.7 1363.8,1669 1442.1,1669 	" />
          </animated.g>
        )
    }

    export function UnusLTop(props) {
        return(
            <animated.g {...props} id="Layer_47">
            <polygon points="1587.3,1661.2 1587.3,1657.8 1587.3,1511.3 1545.9,1511.3 1545.9,1698.9 1587.3,1698.9 	" />
          </animated.g>
        )
    }

    export function UnusLBottom(props) {
        return(
            <animated.g {...props} id="Layer_46">
            <rect x="1587.3" y="1661.2" width="73.2" height="37.7" />
          </animated.g>
        )
    }

    export function EyesE1Top(props){
        return(
            <animated.g {...props} id="eyes-e1-high">
            <polygon points="645.7,1818.1 732.4,1818.1 732.4,1780.4 604.3,1780.4 604.3,1853.3 645.7,1853.3 	" />
          </animated.g>
        )
    }
    export function EyesE1Mid(props){
        return(
            <animated.g {...props} id="eyes-e1-low">
            <rect x="604.3" y="1853.3" width="122.4" height="36.9" />
          </animated.g>
        )
    }

    export function EyesE1Bottom(props){
        return(
            <animated.g {...props} id="eyes-e1-low_00000163071357564641264300000015628838037258777760_">
            <polygon points="645.7,1930.3 645.7,1890.3 604.3,1890.3 604.3,1967.9 734.3,1967.9 734.3,1930.3 	" />
          </animated.g>
        )
    }
    export function EyesYLeft(props){
        return(
            <animated.g {...props} id="eyes-y-left">
            <polygon points="865,1858.4 820.3,1780.4 775.4,1780.4 844.2,1897.8 865,1897.8 	" />
          </animated.g>
        )
    }


    export function EyesYMid(props){
        return(
            <animated.g {...props} id="eyes-y-btm">
            <polygon points="844.2,1897.9 844.3,1898 844.3,1967.9 885.7,1967.9 885.7,1898 885.8,1897.9 	" />
          </animated.g>
        )
    }

    export function EyesYRight(props){
        return(
            <animated.g {...props} id="eyes-y-right">
            <polygon points="909.7,1780.4 865,1858.4 865,1858.4 865,1897.8 885.8,1897.8 954.6,1780.4 	" />
          </animated.g>
        )
    }

    export function EyesE2Top(props){
        return(
            <animated.g {...props} id="eyes-e2-high">
            <polygon points="1041,1818.1 1127.6,1818.1 1127.6,1780.4 999.6,1780.4 999.6,1853.3 1041,1853.3 	" />
          </animated.g>
        )
    }
    export function EyesE2Mid(props){
        return(
            <animated.g {...props} id="eyes-e2-mid">
            <rect x="999.6" y="1853.3" width="122.4" height="36.9" />
          </animated.g>
        )
    }
    export function EyesE2Bottom(props){
        return(
            <animated.g {...props} id="eyes-e2-low">
            <polygon points="1041,1930.3 1041,1890.3 999.6,1890.3 999.6,1967.9 1129.5,1967.9 1129.5,1930.3 	" />
          </animated.g>
        )
    }
    export function EyesSTop(props){
        return(
            <animated.g {...props} id="Layer_32">
            <path
              d="M1270.2,1858l-40.9,26.8c0,0,0.1,0,0.1,0c26.1,9.7,47.1,13.7,47.1,30.1c0,8.9-7.3,18.6-28,18.6c-21.8,0-38.2-10-47.1-24.8
             l-30.1,20.7c11.6,22.6,36.6,41.4,77,41.4c47.6,0,69.7-24.2,69.7-57.3C1318,1886.7,1304,1870.9,1270.2,1858z"
            />
          </animated.g>
        )
    }
    export function EyesSBottom(props){
        return(
            <animated.g {...props} id="Layer_31">
            <path
              d="M1229.3,1884.8l40.9-26.8c-0.1,0-0.1,0-0.2-0.1c-30.4-11.8-45.2-12.4-45.2-28c0-8.9,9.4-15.3,24.8-15.3
             c16.1,0,27.2,6.7,33.9,16.1l27.7-22.1c-11.6-16.1-30.9-31.2-62.7-31.2c-34.4,0-64.6,19.4-64.6,53
             C1184,1860.5,1202.5,1875.1,1229.3,1884.8z"
            />
          </animated.g>
        )
    }

