import React, { useEffect, useState } from "react";
import { useSpring, animated, config, useTrail, useChain,  useSpringRef, useTransition } from "react-spring";
import { Box, Flex,Center, Heading, Text, Icon } from "@chakra-ui/react"
import useMouse from '@react-hook/mouse-position'
import { AtALeft, AtAMiddle, AtARight, AtTBottom, AtTTop, EyesE1Bottom, EyesE1Mid, EyesE1Top, EyesE2Bottom, EyesE2Mid, EyesE2Top, EyesSBottom, EyesSTop, EyesYLeft, EyesYMid, EyesYRight, LookKBottom, LookKLong, LookKTop, LookL1, LookLShort, LookO2Cirlce, LookO2Left, LookO2Right, LookOLeft, LookORight, ThingsGBottom, ThingsGMiddle, ThingsGTop, ThingsHLeft, ThingsHMiddle, ThingsHRight, ThingsI, ThingsNLeft, ThingsNMiddle, ThingsNRight, ThingsSBottom, ThingsSTop, ThingsTBottom, ThingsTTop, UnusALeft, UnusAMid, UnusARight, UnusLBottom, UnusLTop, UnusNLeft, UnusNMid, UnusNRight, UnusSBottom, UnusSTop, UnusU1Left, UnusU1Right, UnusU2Left, UnusU2Right, UnusU3Left, UnusU3Right, UsualALeft, UsualAMiddle, UsualARight, UsualLLong, UsualLShort, UsualSBottom, UsualSTop, UsualU1Left, UsualU1Right, UsualU2Left, UsualU2Right, WithHLeft, WithHMiddle, WithHRight, WithI, WithTBottom, WithTTop, WithWLeft, WithWMiddle, WithWRight } from "../components/look/Animations";
import ProjectArrows, { ProjectLinkers } from "../components/Header/ProjectArrows";








const Look = ({location}) => {

    

 const config1 = {tension: 100, friction: 30, mass:5}
 const config2 = {tension: 150, friction: 50, mass: 10}
 const config3 = {tension: 100, friction: 30, mass:5}
 const config4 = {tension: 100, friction: 30, mass:5}
 const config5 = {tension: 100, friction: 30, mass:5}
 const config6 = {tension: 100, friction: 30, mass:5}
 const config7 = {tension: 100, friction: 30, mass:5}
 const config8 = config.wobbly
 const config9 = config.stiff
 const config10 = config.slow
 const config11 = config.molasses
 const config12 = config.gentle

 const slower = 4500
 const slow = 3000
 const quick = 1500
 const quicker = 1000
 const snap = 500

 const returnDelay1 = 800
 const returnDelay2 = 1200
 const returnDelay3 = 1500
 const returnDelay5 = 1800
 const returnDelay6 = 2000

 const returnDelay = {
     0: 500,
     1: 800,
     2: 1200,
     3: 1500,
     4: 1800,
     5: 2000,
     6: 2300,
     7: 2500,
     8: 2800,
     9: 3000
     
 }

 const conf = {
     slowest: 5000,
     slower: 4500,
     slow: 3500,
     mid: 3000,
     fast: 2500,
     faster: 2000,
     fastest: 1200,
     snap: 500
 }

 



const ref = React.useRef(null)

    // const ref = React.useRef(null)
    // const mouse = useMouse(ref, {
    //     enterDelay:0,
    //     leaveDelay:0,
    //     fps: 1,
    // })

    //     useEffect(() => {
    //     if(mouse.isOver) {
    //         return setToggle(true)
    //     }else return setToggle(false)
    // }, [mouse])



  const [toggle, setToggle] = useState(false);
    const [atToggle, setAtToggle] = useState(false)
    const [usualToggle, setUsualToggle] = useState(false)
    const [thingsToggle, setThingsToggle] = useState(false)
    const [withToggle, setWithToggle] = useState(false)
    const [unusualToggle, setUnusualToggle] = useState(false)
    const [eyesToggle, setEyesToggle] = useState(false)

  const testSpring = useSpring({
      from: {transform: `rotateZ(5deg)`},
      to: { transform: toggle ? `rotateZ(0deg)` : `rotateZ(5deg)`},
      loop: {reverse: toggle ? false : true},
      config: {duration: toggle ? snap : 800}
  })

  const testSpring2 = useSpring({
   from: {transform: `translate3d(-400px,-20px,0px) rotateZ(0deg)`},
   to: { transform: toggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(-300px,-30px,-50px) rotateZ(360deg)`},
   to: { transform: toggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(-300px,-30px,-50px) rotateZ(360deg)`},
    loop: {reverse: toggle ? false : true},
    config: {duration: toggle ? snap : 1200}
})

const testSpring3 = useSpring({
    from: {transform: `translate3d(-450px,0px,0px) rotateZ(0deg) scale(0.5)`, transformBox: `fillBox`, transformOrigin: `center`},
    to: { transform: toggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0) ` : `translate3d(-450px,0px,-50px) rotateZ(360deg) scale(0.5)`, transformBox: toggle ? `contentBox` : `fillBox`, transformOrigin: `center`},
     loop: toggle ? false : true,
     config: {duration: toggle ? conf.snap : 1000}
 })

 const testSpring33 = useSpring({
    from: {transform: `translate3d(-375px,0px,0px) rotateZ(0deg)`, transformBox: `fillBox`, transformOrigin: `center`},
    to: { transform: toggle ? `translate3d(0px,0px,0px) rotateZ(0deg) ` : `translate3d(-375px,0px,0px) rotateZ(360deg)`, transformBox: toggle ? `contentBox` : `fillBox`, transformOrigin: `center`},
     loop: toggle ? false : true,
     config: {duration: toggle ? snap : 1000}
 })


 const testSpring4 = useSpring({
    from: {transform: `translate3d(-350px,0px,0px) rotateZ(0deg) scale(0.4)`},
    to: { transform: toggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-350px,0px,0px) rotateZ(360deg) scale(0.4)`},
     loop: toggle ? false : true,
     config: {duration: toggle ? snap : 1000}
 })
 const testSpring44 = useSpring({
    from: {transform: `translate3d(-150px,-50px,0px) rotateZ(0deg)`},
    to: { transform: toggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(-150px,-50px,0px) rotateZ(-360deg)`},
    delay: 0,
     loop: toggle ? false : true,
     config: {duration: toggle ? snap : 2000}
 })

 const testSpring5 = useSpring({
    from: {transform: `translate3d(100px,0px,0px) rotateZ(0deg)`},
    to: { transform: toggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(100px,0px,0px) rotateZ(360deg)`},
     loop: toggle ? false : true,
     config: {duration: toggle ? snap : 2000}
 })

 const testSpring52 = useSpring({
    from: {transform: `translate3d(-50px,50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: toggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-50px,50px,0px) rotateZ(360deg) scale(0.6)`},
     loop: toggle ? false : true,
     config: {duration: toggle ? snap : 2000}
 })


 const testSpring6 = useSpring({
    from: {transform: `translate3d(275px,-75px,0px) rotateZ(0deg)`},
    to: { transform: toggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(275px,-75px,-50px) rotateZ(360deg)`},
     loop: toggle ? false : true,
     config: {duration: toggle ? snap : 2000}
 })

 const testSpring7 = useSpring({
    from: {transform: `translate3d(350px,100px,0px) rotateZ(0deg) scale(0.5)`},
    to: { transform: toggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(350px,100px,-50px) rotateZ(360deg) scale(0.5)`},
     loop: toggle ? false : true,
     config:{duration: toggle ? snap : 1200}
 })

 const testSpring77 = useSpring({
    from: {transform: `translate3d(450px,-100px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: toggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(450px,-100px,0px) rotateZ(360deg) scale(0.6)`},
     loop: toggle ? false : true,
     config:{duration: toggle ? snap : 1200}
 })

 const testSpring8 = useSpring({
    from: {transform: `translate3d(300px,20px,0px) scale(0.4)`},
    to: { transform: toggle ? `translate3d(0px,0px,0px) scale(1.0)` : `translate3d(350px,20px,-50px) scale(0.4)`},
    loop: {reverse: toggle ? false : true},
    config: {duration: toggle ? snap : 800}
})



  const lookO1CircleSpring = useSpring({
    from: {
      transform: toggle
        ? "translate3d(-300px,0px,0px) "
        : "translate3d(0px,0px,0px)",
    },
    to: {
      transform: toggle
        ? "translate3d(0px, 0px, 0px)"
        : "translate3d(-300px,0px,0px)",
    },
    loop: true,
    config: config.slow,
  });


  const AtLeftSpring = useSpring({
    from: {transform: `translate3d(-375px,0px,0px) rotateZ(0deg)`, transformBox: `fillBox`, transformOrigin: `center`},
    to: { transform: atToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) ` : `translate3d(-375px,0px,0px) rotateZ(360deg)`, transformBox: atToggle ? `contentBox` : `fillBox`, transformOrigin: `center`},
     loop: atToggle ? false : true,
     config: {duration: atToggle ? snap : 1000}
 })

 const AtMiddleSpring = useSpring({
    from: {transform: `translate3d(-150px,-50px,0px) rotateZ(0deg)`},
    to: { transform: atToggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(-150px,-50px,0px) rotateZ(-360deg)`},
    delay: 0,
     loop: atToggle ? false : true,
     config: {duration: atToggle ? snap : 2000}
 })

 const AtRightSpring = useSpring({
    from: {transform: `translate3d(0px,50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: atToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(0px,50px,0px) rotateZ(360deg) scale(0.6)`},
     loop: atToggle ? false : true,
     config: {duration: atToggle ? snap : 2000}
 })

 const AtTTopSpring = useSpring({
    from: {transform: `translate3d(200px,0px,0px) rotateZ(0deg)`},
    to: { transform: atToggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(200px,0px,0px) rotateZ(360deg)`},
     loop: atToggle ? false : true,
     config:{duration: atToggle ? snap : 1200}
 })

 const AtTBottomSpring = useSpring({
    from: {transform: `translate3d(350px,-20px,0px) rotateZ(0deg) scale(0.5)`},
    to: { transform: atToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(350px,-20px,0px) rotateZ(360deg) scale(0.5)`},
     loop: atToggle ? false : true,
     config:{duration: atToggle ? snap : 1200}
 })

 const UsualU1LeftSpring = useSpring({
    from: {transform: `translate3d(-350px,50px,0px) rotateZ(0deg)`},
    to: { transform: usualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(-350px,50px,0px) rotateZ(-360deg)`},
    delay: 0,
     loop: usualToggle ? false : true,
     config: {duration: usualToggle ? snap : 2000}
 })

 const UsualU1RightSpring = useSpring({
    from: {transform: `translate3d(-150px,-50px,0px) rotateZ(0deg)`},
    to: { transform: usualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(-150px,-50px,0px) rotateZ(360deg)`},
    delay: 0,
     loop: usualToggle ? false : true,
     config: {duration: usualToggle ? snap : 2000}
 })

 const UsualSTopSpring = useSpring({
    from: {transform: `translate3d(-250px,125px,0px) rotateZ(0deg) scale(0.5)`},
    to: { transform: usualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-250px,125px,0px) rotateZ(360deg) scale(0.5)`},
    delay: 0,
     loop: usualToggle ? false : true,
     config: {duration: usualToggle ? snap : 2000}
 })
 const UsualSBottomSpring = useSpring({
    from: {transform: `translate3d(0px,0px,0px) rotateZ(0deg)`},
    to: { transform: usualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(0px,0px,0px) rotateZ(360deg)`},
    delay: 0,
     loop: usualToggle ? false : true,
     config: {duration: usualToggle ? snap : 2000}
 })

 const UsualALeftSpring = useSpring({
    from: {transform: `translate3d(150px,0px,0px) rotateZ(0deg) scale(0.7)`},
    to: { transform: usualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(150px,0px,0px) rotateZ(360deg) scale(0.7)`},
    delay: 0,
     loop: usualToggle ? false : true,
     config: {duration: usualToggle ? snap : 2000}
 })

 const UsualARightSpring = useSpring({
    from: {transform: `translate3d(290px,40px,0px) rotateZ(0deg)`},
    to: { transform: usualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(290px,40px,0px) rotateZ(360deg)`},
    delay: 0,
     loop: usualToggle ? false : true,
     config: {duration: usualToggle ? snap : conf.slowest}
 })

 const UsualAMiddleSpring = useSpring({
    from: {transform: `translate3d(120px,50px,0px) rotateZ(0deg)`},
    to: { transform: usualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(120px,50px,0px) rotateZ(-360deg)`},
    delay: 0,
     loop: usualToggle ? false : true,
     config: {duration: usualToggle ? snap : 2000}
 })

 const UsualU2LeftSpring = useSpring({
    from: {transform: `translate3d(0px,0px,0px) rotateZ(0deg) scale(0.4)`},
    to: { transform: usualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(0px,0px,0px) rotateZ(360deg) scale(0.4)`},
    delay: 0,
     loop: usualToggle ? false : true,
     config: {duration: usualToggle ? snap : 2000}
 })

 const UsualU2RightSpring = useSpring({
    from: {transform: `translate3d(120px,0px,0px) rotateZ(0deg)`},
    to: { transform: usualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(120px,0px,0px) rotateZ(-360deg)`},
    delay: 0,
     loop: usualToggle ? false : true,
     config: {duration: usualToggle ? snap : 2000}
 })

 const UsualLLongSpring = useSpring({
    from: {transform: `translate3d(450px,25px,0px) rotateZ(0deg)`},
    to: { transform: usualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(450px,25px,0px) rotateZ(-360deg)`},
     loop: usualToggle ? false : true,
     config:{duration: usualToggle ? snap : 1800}
 })

 const UsualLShortSpring = useSpring({
    from: {transform: `translate3d(300px,-25px,0px) rotateZ(0deg)`},
    to: { transform: usualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg)` : `translate3d(300px,-25px,0px) rotateZ(360deg)`},
     loop: usualToggle ? false : true,
     config:{duration: usualToggle ? snap : 1800}
 })

 const ThingsSpringTTop = useSpring({
    from: {transform: `translate3d(-150px,-20px,0px) rotateZ(0deg) scale(0.4)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-150px,-20px,0px) rotateZ(-360deg) scale(0.4)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : 2000}
})

const ThingsSpringTBottom = useSpring({
    from: {transform: `translate3d(-25px,50px,0px) rotateZ(0deg) scale(0.8)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-25px,50px,0px) rotateZ(360deg) scale(0.8)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : 2000}
})

const ThingsSpringHLeft = useSpring({
    from: {transform: `translate3d(100px,25px,0px) rotateZ(0deg) scale(0.4)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(100px,25px,0px) rotateZ(360deg) scale(0.4)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : 2000}
})
const ThingsSpringHRight = useSpring({
    from: {transform: `translate3d(50px,50px,0px) rotateZ(0deg) scale(0.5)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(50px,50px,0px) rotateZ(-360deg) scale(0.5)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : 2000}
})

const ThingsSpringHMiddle = useSpring({
    from: {transform: `translate3d(-150px,-50px,0px) rotateZ(0deg) scale(0.7)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-150px,-50px,0px) rotateZ(360deg) scale(0.7)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : 2000}
})

const ThingsSpringI = useSpring({
    from: {transform: `translate3d(0px,-50px,0px) rotateZ(0deg) scale(0.7)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(0px,-50px,0px) rotateZ(360deg) scale(0.7)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : 2000}
})

const ThingsSpringNLeft = useSpring({
    from: {transform: `translate3d(0px,50px,0px) rotateZ(0deg) scale(0.7)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(0px,50px,0px) rotateZ(360deg) scale(0.7)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : 2000}
})

const ThingsSpringNMiddle = useSpring({
    from: {transform: `translate3d(50px,-50px,0px) rotateZ(0deg) scale(0.3)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(50px,-50px,0px) rotateZ(360deg) scale(0.3)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : 2000}
})

const ThingsSpringNRight = useSpring({
    from: {transform: `translate3d(150px,50px,0px) rotateZ(0deg) scale(0.7)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(150px,50px,0px) rotateZ(-360deg) scale(0.7)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : 2000}
})

const ThingsSpringGTop = useSpring({
    from: {transform: `translate3d(-50px,-50px,0px) rotateZ(0deg) scale(0.5)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-50px,-50px,0px) rotateZ(360deg) scale(0.5)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : 2000}
})

const ThingsSpringGBottom = useSpring({
    from: {transform: `translate3d(100px,-50px,0px) rotateZ(0deg) scale(0.7)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(100px,-50px,0px) rotateZ(-360deg) scale(0.7)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : conf.slowest}
})

const ThingsSpringGMiddle = useSpring({
    from: {transform: `translate3d(150px,-50px,0px) rotateZ(0deg) scale(0.3)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(150px,-50px,0px) rotateZ(360deg) scale(0.3)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : 2000}
})




 const ThingsSpringSTop = useSpring({
    from: {transform: `translate3d(250px,-25px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(250px,-25px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : 2000}
 })

 const ThingsSpringSBottom = useSpring({
    from: {transform: `translate3d(100px,25px,0px) rotateZ(0deg) scale(0.9)`},
    to: { transform: thingsToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(100px,25px,0px) rotateZ(360deg) scale(0.9)`},
    delay: 0,
     loop: thingsToggle ? false : true,
     config: {duration: thingsToggle ? snap : 2000}
})

const WithSpringWLeft = useSpring({
    from: {transform: `translate3d(-350px,35px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: withToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-350px,35px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: withToggle ? false : true,
     config: {duration: withToggle ? snap : 2000}
})

const WithSpringWMiddle = useSpring({
    from: {transform: `translate3d(-200px,-30px,0px) rotateZ(0deg) scale(1.3)`},
    to: { transform: withToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-200px,-30px,0px) rotateZ(-360deg) scale(1.3)`},
    delay: 0,
     loop: withToggle ? false : true,
     config: {duration: withToggle ? snap : 2000}
})

const WithSpringWRight = useSpring({
    from: {transform: `translate3d(-100px,65px,0px) rotateZ(0deg) scale(0.7)`},
    to: { transform: withToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-100px,65px,0px) rotateZ(360deg) scale(0.7)`},
    delay: 0,
     loop: withToggle ? false : true,
     config: {duration: withToggle ? snap : 2000}
})

const WithSpringI = useSpring({
    from: {transform: `translate3d(0px,25px,0px) rotateZ(0deg) scale(0.9)`},
    to: { transform: withToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(0px,25px,0px) rotateZ(-360deg) scale(0.9)`},
    delay: 0,
     loop: withToggle ? false : true,
     config: {duration: withToggle ? snap : 2000}
})

const WithSpringTTop = useSpring({
    from: {transform: `translate3d(100px,25px,0px) rotateZ(0deg) scale(0.9)`},
    to: { transform: withToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(100px,25px,0px) rotateZ(360deg) scale(0.9)`},
    delay: 0,
     loop: withToggle ? false : true,
     config: {duration: withToggle ? snap : 2000}
})

const WithSpringTBottom = useSpring({
    from: {transform: `translate3d(33px,50px,0px) rotateZ(0deg) scale(0.5)`},
    to: { transform: withToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(33px,50px,0px) rotateZ(360deg) scale(0.5)`},
    delay: 0,
     loop: withToggle ? false : true,
     config: {duration: withToggle ? snap : 2000}
})

const WithSpringHLeft = useSpring({
    from: {transform: `translate3d(100px,25px,0px) rotateZ(0deg) scale(0.5)`},
    to: { transform: withToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(100px,25px,0px) rotateZ(360deg) scale(0.5)`},
    delay: 0,
     loop: withToggle ? false : true,
     config: {duration: withToggle ? snap : 2000}
})

const WithSpringHMiddle = useSpring({
    from: {transform: `translate3d(250px,45px,0px) rotateZ(0deg) scale(0.7)`},
    to: { transform: withToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(250px,45px,0px) rotateZ(360deg) scale(0.7)`},
    delay: 0,
     loop: withToggle ? false : true,
     config: {duration: withToggle ? snap : 2000}
})

const WithSpringHRight = useSpring({
    from: {transform: `translate3d(325px,-60px,0px) rotateZ(0deg) scale(0.4)`},
    to: { transform: withToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(325px,-60px,0px) rotateZ(360deg) scale(0.4)`},
    delay: 0,
     loop: withToggle ? false : true,
     config: {duration: withToggle ? snap : 2000}
})

const UnusSpringU1Left = useSpring({
    from: {transform: `translate3d(-150px,-50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-150px,-50px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : 2000}
})

const UnusSpringU1Right = useSpring({
    from: {transform: `translate3d(-100px,50px,0px) rotateZ(0deg) scale(0.8)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-100px,50px,0px) rotateZ(-360deg) scale(0.8)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : 2000}
})

const UnusSpringNLeft = useSpring({
    from: {transform: `translate3d(-150px,-90px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-150px,-90px,0px) rotateZ(-360deg) scale(0.6)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : 2000}
})

const UnusSpringNMid = useSpring({
    from: {transform: `translate3d(-70px,100px,0px) rotateZ(0deg) scale(0.3)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-70px,100px,0px) rotateZ(360deg) scale(0.3)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : 2000}
})

const UnusSpringNRight = useSpring({
    from: {transform: `translate3d(-150px,0px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-150px,0px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : conf.fastest}
})

const UnusSpringU2Left = useSpring({
    from: {transform: `translate3d(-150px,-50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-150px,-50px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : conf.slow}
})

const UnusSpringU2Right = useSpring({
    from: {transform: `translate3d(-45px,70px,0px) rotateZ(0deg) scale(0.9)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-45px,70px,0px) rotateZ(360deg) scale(0.9)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : conf.slower}
})

const UnusSpringSTop = useSpring({
    from: {transform: `translate3d(0px,25px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(0px,25px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : 2000}
})

const UnusSpringSBottom = useSpring({
    from: {transform: `translate3d(-150px,-133px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-150px,-133px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : 2000}
})

const UnusSpringU3Left = useSpring({
    from: {transform: `translate3d(0px,100px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(0px,100px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : 2000}
})

const UnusSpringU3Right = useSpring({
    from: {transform: `translate3d(-150px,-50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-150px,-50px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : 2000}
})

const UnusSpringALeft = useSpring({
    from: {transform: `translate3d(75px,50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(75px,50px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : 2000}
})

const UnusSpringAMid = useSpring({
    from: {transform: `translate3d(-150px,-50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-150px,-50px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : 2000}
})

const UnusSpringARight = useSpring({
    from: {transform: `translate3d(-50px,-50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-50px,-50px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : 2000}
})

const UnusSpringLTop = useSpring({
    from: {transform: `translate3d(150px,-50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(150px,-50px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : 2000}
})

const UnusSpringLBottom = useSpring({
    from: {transform: `translate3d(-50px,-50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: unusualToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-50px,-50px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: unusualToggle ? false : true,
     config: {duration: unusualToggle ? snap : 2000}
})

const EyesSpringE1Top = useSpring({
    from: {transform: `translate3d(-350px,0px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: eyesToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-350px,0px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: eyesToggle ? false : true,
     config: {duration: eyesToggle ? snap : conf.slow}
})
const EyesSpringE1Mid = useSpring({
    from: {transform: `translate3d(-225px,100px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: eyesToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-225px,100px,0px) rotateZ(-360deg) scale(0.6)`},
    delay: 0,
     loop: eyesToggle ? false : true,
     config: {duration: eyesToggle ? snap : conf.faster}
})
const EyesSpringE1Bottom = useSpring({
    from: {transform: `translate3d(0px,-50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: eyesToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(0px,-50px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: eyesToggle ? false : true,
     config: {duration: eyesToggle ? snap : 2000}
})
const EyesSpringYLeft = useSpring({
    from: {transform: `translate3d(-300px,0px,0px) rotateZ(0deg) scale(1.2)`},
    to: { transform: eyesToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-300px,0px,0px) rotateZ(360deg) scale(1.2)`},
    delay: 0,
     loop: eyesToggle ? false : true,
     config: {duration: eyesToggle ? snap : 2000}
})

const EyesSpringYMid = useSpring({
    from: {transform: `translate3d(-50px,50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: eyesToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-50px,50px,0px) rotateZ(-360deg) scale(0.6)`},
    delay: 0,
     loop: eyesToggle ? false : true,
     config: {duration: eyesToggle ? snap : 2000}
})

const EyesSpringYRight = useSpring({
    from: {transform: `translate3d(-50px,-50px,0px) rotateZ(0deg) scale(0.8)`},
    to: { transform: eyesToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-50px,-50px,0px) rotateZ(360deg) scale(0.8)`},
    delay: 0,
     loop: eyesToggle ? false : true,
     config: {duration: eyesToggle ? snap : 2000}
})

const EyesSpringE2Top = useSpring({
    from: {transform: `translate3d(100px,0px,0px) rotateZ(0deg) scale(0.9)`},
    to: { transform: eyesToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(100px,0px,0px) rotateZ(360deg) scale(0.9)`},
    delay: 0,
     loop: eyesToggle ? false : true,
     config: {duration: eyesToggle ? snap : 2000}
})
const EyesSpringE2Mid = useSpring({
    from: {transform: `translate3d(-50px,-50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: eyesToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-50px,-50px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: eyesToggle ? false : true,
     config: {duration: eyesToggle ? snap : 2000}
})
const EyesSpringE2Bottom = useSpring({
    from: {transform: `translate3d(-50px,50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: eyesToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(-50px,50px,0px) rotateZ(-360deg) scale(0.6)`},
    delay: 0,
     loop: eyesToggle ? false : true,
     config: {duration: eyesToggle ? snap : 2000}
})

const EyesSpringSTop = useSpring({
    from: {transform: `translate3d(250px,-50px,0px) rotateZ(0deg) scale(0.6)`},
    to: { transform: eyesToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(250px,-50px,0px) rotateZ(360deg) scale(0.6)`},
    delay: 0,
     loop: eyesToggle ? false : true,
     config: {duration: eyesToggle ? snap : 2000}
})

const EyesSpringSBottom = useSpring({
    from: {transform: `translate3d(60px,100px,0px) rotateZ(0deg) scale(1.5)`},
    to: { transform: eyesToggle ? `translate3d(0px,0px,0px) rotateZ(0deg) scale(1.0)` : `translate3d(60px,100px,0px) rotateZ(-360deg) scale(1.5)`},
    delay: 0,
     loop: eyesToggle ? false : true,
     config: {duration: eyesToggle ? snap : 2000}
})

 //! End PATH components

 //! Begin MOUSE DETECTION FUNCTIONS

 const leavingLook = () => {
     setTimeout(() => {
         setToggle(false)
     }, returnDelay[7]);
 }

 const leavingAt = () => {
    setTimeout(() => {
        setAtToggle(false)
    }, returnDelay[7]);
}

 const leavingUsual = () => {
     setTimeout(() => {
         setUsualToggle(false)
     }, returnDelay[7]);
 }

 const leavingThings = () => {
     setTimeout(() => {
         setThingsToggle(false)
     }, returnDelay[7]);
 }

 const leavingWith = () => {
     setTimeout(() => {
         setWithToggle(false)
     }, returnDelay[7]);
 }

 const leavingUnusual = () => {
     setTimeout(() => {
         setUnusualToggle(false)
     }, returnDelay[7]);
 }

 const leavingEyes = () => {
     setTimeout(() => {
         setEyesToggle(false)
     }, returnDelay[7]);
 }
 
 

 


 
  return (
    <>
      <style jsx global>{`
        .st0 {
          display: none;
        }
        .st1 {
          display: inline;
        }
      `}</style>
      {/* <Heading textAlign='center'>X: {mouse.x} Y: {mouse.y}</Heading> */}
      {/* <Flex bg='grey' justify='center' minH='100vh' align='center'> */}
      <Center minH="100vh" w="100vw">
      <svg 
      //preserveAspectRatio="xMinYMin meet"
        version="1.1"
       
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        height="100vh"
        width="100vw"
       // width="100vw"
        
        viewBox="0 0 1920 2160"
        xmlSpace="preserve"
      >
                      <path fill="green" stroke="blue" strokeWidth="2" d="
            M 792.1,296.7
            a 35,35 1,0 70,0
            a 35,35 1,0 -70,0
            " />
          <g id="bg">
              <rect fill="none" x="0" y="0" width="1920" height="2160"/>
              </g>
        


        <g id="Layer_77" className="st0">
          <path
            className="st1"
            d="M999.6,1780.4h128.1v37.7H1041v35.2h81v36.9h-81v40.1h88.5v37.7h-130V1780.4z"
          />
          
        </g>
        <g ref={ref}    onMouseOver={() => setToggle(true)} onMouseOut={leavingLook}
         id="lookGroup">
          
        <animated.g
          id="look-o1-circle"
        >

          <animated.circle
           // transform="translate(-300,-200)"
            
            cx="827.1"
            cy="296.7"
            r="24.9"
          ><animatedMotion dur="6s" repeatCount="indefinite" path=" M 792.1,296.7
          a 35,35 1,0 70,0
          a 35,35 1,0 -70,0
          "/></animated.circle>
        </animated.g>
        
       <LookO2Cirlce style={{...testSpring8}} />
       
        <LookL1 style={{...testSpring3}} />
    
       <LookLShort style={{...testSpring33}} />
<LookORight style={{...testSpring44}} />
        <LookOLeft style={{...testSpring4}} />
        <LookO2Right style={{...testSpring5}} />
        <LookO2Left style={{...testSpring52}} />
       <LookKLong style={{...testSpring6}} />
       <LookKTop style={{...testSpring7}} />
     <LookKBottom style={{...testSpring77}} />
        <rect id="lookBox" x="0" y="100" fill="rgb(0,0,0,0.0)" width="1920" height="300"/>
        </g>


        <g onMouseOver={() => setAtToggle(true)}onMouseLeave={leavingAt}>
       
        <AtAMiddle style={{...AtMiddleSpring}} />
        <AtALeft style={{...AtLeftSpring}} />
        <AtARight style={{...AtRightSpring}} />
        <AtTTop style={{...AtTTopSpring}} />
        <AtTBottom style={{...AtTBottomSpring}}/>
        <rect id="lookBox" x="0" y="400" fill="rgb(0,0,0,0.0)" width="1920" height="300"/>
</g>





        <g onMouseOver={() => setUsualToggle(true)}onMouseLeave={leavingUsual}>
        <UsualU1Left style={{...UsualU1LeftSpring}} />
        <UsualU1Right style={{...UsualU1RightSpring}}/>
        <UsualSTop style={{...UsualSTopSpring}}  />
       <UsualSBottom style={{...UsualSBottomSpring}}/>
       <UsualU2Left style={{...UsualU2LeftSpring}}/>
       <UsualU2Right style={{...UsualU2RightSpring}} />
       <UsualALeft style={{...UsualALeftSpring}} />
       <UsualARight style={{...UsualARightSpring}} />
       <UsualAMiddle style={{...UsualAMiddleSpring}} />
       <UsualLLong style={{...UsualLLongSpring}} />
       <UsualLShort style={{...UsualLShortSpring}} />
        <rect id="lookBox" x="0" y="650" fill="rgb(0,0,0,0.0)" width="1920" height="300"/>
</g>

<g id="thingsGroup" onMouseOver={() => setThingsToggle(true)} onMouseLeave={leavingThings}>
        <ThingsTTop style={{...ThingsSpringTTop}}  />
        <ThingsTBottom style={{...ThingsSpringTBottom}} />
        <ThingsHLeft style={{...ThingsSpringHLeft}} />
        <ThingsHRight style={{...ThingsSpringHRight}} />
        <ThingsHMiddle style={{...ThingsSpringHMiddle}} />
        <ThingsI style={{...ThingsSpringI}}/>
        <ThingsNLeft style={{...ThingsSpringNLeft}} />
        <ThingsNRight style={{...ThingsSpringNRight}} />
        <ThingsNMiddle style={{...ThingsSpringNMiddle}} />
        <ThingsGTop style={{...ThingsSpringGTop}} />
        <ThingsGBottom style={{...ThingsSpringGBottom}} />
        <ThingsGMiddle  style={{...ThingsSpringGMiddle}}/>
        <ThingsSTop style={{...ThingsSpringSTop}} />
        <ThingsSBottom style={{...ThingsSpringSBottom}}/>
        <rect id="lookBox" x="0" y="900" fill="rgb(0,0,0,0.0)" width="1920" height="300"/>
</g>

<g onMouseOver={() => setWithToggle(true)} onMouseLeave={leavingWith}>
    <WithWLeft style={{...WithSpringWLeft}} />
    <WithWMiddle style={{...WithSpringWMiddle}}  />
    <WithWRight style={{...WithSpringWRight}}  />
    <WithI style={{...WithSpringI}}  />
    <WithTTop style={{...WithSpringTTop}}  />
    <WithTBottom style={{...WithSpringTBottom}}  />
    <WithHLeft style={{...WithSpringHLeft}}  />
    <WithHMiddle style={{...WithSpringHMiddle}}  />
    <WithHRight style={{...WithSpringHRight}}  />
    <rect id="lookbox" x="0" y="1175" fill="rgb(0,0,0,0.0)" width="1920" height="300" />
    </g>

<g onMouseOver={() => setUnusualToggle(true)} onMouseLeave={leavingUnusual}>
<UnusU1Left style={{...UnusSpringU1Left}} />
<UnusU1Right style={{...UnusSpringU1Right}}/>
<UnusNLeft style={{...UnusSpringNLeft}}/>
<UnusNMid style={{...UnusSpringNMid}}/>
<UnusNRight style={{...UnusSpringNRight}}/>
<UnusU2Left style={{...UnusSpringU2Left}}/>
<UnusU2Right style={{...UnusSpringU2Right}}/>
<UnusSTop style={{...UnusSpringSTop}}/>
<UnusSBottom style={{...UnusSpringSBottom}}/>
<UnusU3Left style={{...UnusSpringU3Left}}/>
<UnusU3Right style={{...UnusSpringU3Right}}/>
<UnusALeft style={{...UnusSpringALeft}}/>
<UnusAMid style={{...UnusSpringAMid}}/>
<UnusARight style={{...UnusSpringARight}}/>
<UnusLTop style={{...UnusSpringLTop}}/>
<UnusLBottom style={{...UnusSpringLBottom}}/>
<rect id="lookbox" x="0" y="1455" fill="rgb(0,0,0,0.0)" width="1920" height="300" />
    </g>

    <g onMouseOver={() => setEyesToggle(true)} onMouseLeave={leavingEyes}>
    <EyesE1Top style={{...EyesSpringE1Top}} />
    <EyesE1Mid style={{...EyesSpringE1Mid}}/>
    <EyesE1Bottom style={{...EyesSpringE1Bottom}}/>
    <EyesYLeft style={{...EyesSpringYLeft}}/>
    <EyesYMid style={{...EyesSpringYMid}}/>
    <EyesYRight style={{...EyesSpringYRight}}/>
    <EyesE2Top style={{...EyesSpringE2Top}}/>
    <EyesE2Mid style={{...EyesSpringE2Mid}}/>
    <EyesE2Bottom style={{...EyesSpringE2Bottom}}/>
    <EyesSTop style={{...EyesSpringSTop}}/>
    <EyesSBottom style={{...EyesSpringSBottom}}/>
    <rect id="lookbox" x="0" y="1725" fill="rgb(0,0,0,0.0)" width="1920" height="300" />
    </g>

















 

       


















  







      </svg>
      </Center>
      {/* </Flex> */}
      <ProjectLinkers prevTitle='The Copper Club' prevUrl='/project/copper-club' nextTitle='Le Lapin' nextUrl='/project/le-lapin' />
    </>
  );
};



export default Look;
